import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth:1440) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find((n) => n.node.relativePath.includes(props.filename));
      if (!image) { return null; }

      const imageFluid = image.node.childImageSharp.fluid;
      return (
        <figure style={{ width: '100%' }} className={props.className}>
          <Img
            alt={props.alt}
            fluid={imageFluid}
            imgStyle={props.imageStyle}
          />
        </figure>
      );
    }}
  />
);

export default Image;
